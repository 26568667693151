import { AdvancedVideo } from "@cloudinary/react";
import classNames from "classnames";
import { Button } from "components/button";
import { Cloudinary, CloudinaryVideo } from "components/cloudinary";
import Link from "components/link";
import { LoadingSpinner } from "components/loading-spinner";
import { Shape } from "components/shape";
import { InteractiveMapMarker, InteractiveMapProps } from "constants/types";
import { useFullscreen, useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import { generateRoute, useSlugPath } from "helpers/routing";
import { useRouter } from "next/router";
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { colors } from "theme/theme";
import { Vi } from "types";

// how long should be waited on video to be loaded, in ms
const ZOOM_VIDEO_LOAD_TIMEOUT = 8000;

const InteractiveMap: FC<InteractiveMapProps> = ({
  background,
  markers,
  breadcrumb,
  pc,
}) => {
  const pageLevel = breadcrumb?.length - 1;

  const Media = background.__typename === "Vi" ? CloudinaryVideo : Cloudinary;

  const [backToIndustryLabel] = useLabels(["ui-942", "back to industry"]);
  const parentPath = breadcrumb.at(-2)?.url;
  const { push, prefetch } = useRouter();
  const currentSlugPath = useSlugPath();
  const { locale } = useLocale();
  const [fullscreen, toggleFullscreen] = useFullscreen();

  useEffect(() => {
    if (typeof document === "undefined") return;

    const body = document.querySelector("body");
    body.classList.add("fullscreen-only-interactive-map");

    return () => {
      body.classList.remove("fullscreen-only-interactive-map");
    };
  }, []);

  const zoomOutVideo = pc[0]?.video_zoom_out?.[0];

  const nextUrl = useRef<InteractiveMapMarker["url"]>();
  const [activeZoomVideo, setActiveZoomVideo] = useState<Vi>();
  const [canPlayZoomVideo, setCanPlayZoomVideo] = useState(false);
  const [shouldPlayZoomVideo, setShouldPlayZoomVideo] = useState(false);
  const [zoomVideo, setZoomVideo] = useState<HTMLVideoElement>();
  const zoomVideoRef = useCallback((node: AdvancedVideo) => {
    if (!node) return;
    setZoomVideo(node.videoRef?.current);
  }, []);
  const zoomVideoTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    // reset state when interactive map changes
    setActiveZoomVideo(undefined);
    setCanPlayZoomVideo(false);
    setShouldPlayZoomVideo(false);
    setZoomVideo(undefined);
    nextUrl.current = undefined;
  }, [background, markers]);

  const canPlayTroughHandler = useCallback(() => {
    if (!canPlayZoomVideo) setCanPlayZoomVideo(true);
  }, [canPlayZoomVideo]);

  const endedHandler = useCallback(() => {
    if (zoomVideoTimeout.current) clearTimeout(zoomVideoTimeout.current);
    if (!nextUrl.current) return;
    document.documentElement.dataset.noLoading = "true";
    void push(nextUrl.current, undefined, { scroll: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextUrl.current]);

  const markerHoverHandler = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, marker: InteractiveMapMarker) => {
      nextUrl.current = generateRoute(
        locale,
        marker?.url,
        locale,
        currentSlugPath,
      );
      if (
        !marker?.zoomVideo ||
        activeZoomVideo === marker.zoomVideo ||
        shouldPlayZoomVideo
      )
        return;
      setCanPlayZoomVideo(false);
      setActiveZoomVideo(marker.zoomVideo);
    },
    [activeZoomVideo, currentSlugPath, locale, shouldPlayZoomVideo],
  );

  const markerClickHandler = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, marker: InteractiveMapMarker) => {
      nextUrl.current = generateRoute(
        locale,
        marker?.url,
        locale,
        currentSlugPath,
      );
      if (!marker?.zoomVideo) return;
      event.preventDefault();

      // Prefetch page that should be loaded after video ends
      void prefetch(nextUrl.current);

      if (activeZoomVideo !== marker.zoomVideo) {
        setCanPlayZoomVideo(false);
        setActiveZoomVideo(marker.zoomVideo);
      }
      setShouldPlayZoomVideo(true);
      zoomVideoTimeout.current = setTimeout(() => {
        if (!canPlayZoomVideo) {
          endedHandler();
        }
      }, ZOOM_VIDEO_LOAD_TIMEOUT);
    },
    [
      activeZoomVideo,
      canPlayZoomVideo,
      currentSlugPath,
      endedHandler,
      locale,
      prefetch,
    ],
  );

  const playZoomOut = useCallback(
    (event: MouseEvent) => {
      nextUrl.current = generateRoute(
        locale,
        parentPath,
        locale,
        currentSlugPath,
      );
      if (!zoomOutVideo) return;
      event.preventDefault();

      // Prefetch page that should be loaded after video ends
      void prefetch(nextUrl.current);

      if (activeZoomVideo !== zoomOutVideo) {
        setCanPlayZoomVideo(false);
        setActiveZoomVideo(zoomOutVideo);
      }
      setShouldPlayZoomVideo(true);
      zoomVideoTimeout.current = setTimeout(() => {
        if (!canPlayZoomVideo) {
          endedHandler();
        }
      }, ZOOM_VIDEO_LOAD_TIMEOUT);
    },
    [
      activeZoomVideo,
      canPlayZoomVideo,
      currentSlugPath,
      endedHandler,
      locale,
      parentPath,
      prefetch,
      zoomOutVideo,
    ],
  );

  useEffect(() => {
    if (!canPlayZoomVideo || !shouldPlayZoomVideo || !zoomVideo) return;

    setTimeout(() => void zoomVideo.play(), 1);
  }, [canPlayZoomVideo, shouldPlayZoomVideo, zoomVideo]);

  useEffect(() => {
    if (!zoomVideo) return;
    zoomVideo.addEventListener("canplaythrough", canPlayTroughHandler);
    zoomVideo.addEventListener("ended", endedHandler);

    return () => {
      zoomVideo.removeEventListener("canplaythrough", canPlayTroughHandler);
      zoomVideo.removeEventListener("ended", endedHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomVideo]);

  return (
    <div className="interactive-map">
      <Media
        media={background}
        arBackground="ar169"
        background="grey-triangle"
        loop
        muted
        hideControls
        priority={true}
      />

      <div
        className={classNames(
          "zoomVideo",
          shouldPlayZoomVideo && canPlayZoomVideo && "active",
        )}
      >
        {activeZoomVideo && (
          <CloudinaryVideo
            media={activeZoomVideo}
            arBackground="ar169"
            background="grey-triangle"
            hideControls
            noAutoPlay
            muted
            poster={background}
            ref={zoomVideoRef}
          />
        )}
      </div>

      {shouldPlayZoomVideo && !canPlayZoomVideo && (
        <LoadingSpinner color={colors.white} />
      )}

      <div className={classNames("markers", shouldPlayZoomVideo && "hide")}>
        {markers?.map((marker, index) => {
          const { url, coordinates, label } = marker;
          return (
            <Link
              href={url}
              key={`${index}-${label}`}
              className="marker"
              style={{
                left: `${coordinates.x * 100}%`,
                top: `${coordinates.y * 100}%`,
              }}
              onMouseEnter={(event) => markerHoverHandler(event, marker)}
              onClick={(event) => markerClickHandler(event, marker)}
            >
              <>
                <Shape
                  variant="plus-circle"
                  fill={colors.orange}
                  size={30}
                  className="icon"
                />
                <Shape
                  variant="caret-right-circle"
                  fill={colors.orange}
                  size={30}
                  className="icon hover"
                />
                <p className="label">{label}</p>
              </>
            </Link>
          );
        })}
      </div>

      <div className="interactive-map__buttons">
        {pageLevel === 3 && (
          <Button
            label={backToIndustryLabel.label}
            icon="caret-left"
            onClick={playZoomOut}
            url={parentPath}
          />
        )}
        <Button
          variant="white"
          label=""
          icon={fullscreen ? "windowed" : "fullscreen"}
          onlyIcon
          onClick={toggleFullscreen}
        />
      </div>
    </div>
  );
};

export default InteractiveMap;
