import { Button } from "components/button";
import { Cloudinary } from "components/cloudinary";
import { Col, Container, Row } from "react-bootstrap";
import { FC } from "react";
import { getSpeakingId, sanitize } from "helpers/text-processing";
import { HeroSlideProps, Media } from "constants/types";
import { TipTapComponent } from "components/wrapper/components/text";
import classNames from "classnames";

const HeroSlide: FC<HeroSlideProps> = ({
  headline,
  color,
  subline,
  eye_catcher,
  text,
  buttons = [],
  short_label,
  image,
  priority = null,
}) => {
  return (
    <div className={classNames("slide", color && `slide--${color}`)}>
      <div className="slide__content-wrapper">
        <Container>
          <Row>
            <Col lg={5}>
              <div className="slide__contentArea__content">
                <div
                  className="slide__contentArea__content__subline mb-1"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(subline),
                  }}
                />
                <h2
                  className="h2 slide__contentArea__content__headline"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(headline),
                  }}
                />
                <div className="slide__contentArea__content__text">
                  <TipTapComponent tree={text} />
                </div>
                <div className="slide__contentArea__content__buttons">
                  {Array.isArray(buttons) &&
                    buttons?.map((button, index) => (
                      <Button
                        {...button}
                        url={button.link}
                        label={button.text}
                        variant={button.color}
                        key={`slider-button-${index}`}
                        onClick={() =>
                          // @ts-ignore
                          window?._etracker?.sendEvent(
                            // @ts-ignore
                            new et_UserDefinedEvent(
                              getSpeakingId({ title: short_label }),
                              "Link",
                              "click",
                              "Banner",
                            ),
                          )
                        }
                      />
                    ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="slide__imageArea">
        <div className="slide__imageArea__background eye-catcher-container">
          {eye_catcher && (
            <div className="mt-2 ml-3 position-absolute eye-catcher">
              {eye_catcher}
            </div>
          )}
        </div>
        <div className="slide__imageArea__image">
          {image && (
            <Cloudinary
              media={image as Media}
              ar="ar169"
              responsiveOrientation="height"
              priority={priority}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroSlide;
