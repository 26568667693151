// Do not change order of imports
// Else you could get following error:
// ReferenceError: Cannot access '__WEBPACK_DEFAULT_EXPORT__' before initialization
import { getRedis } from "cmd/warmup/_redis";
import { Overview } from "components/overview";
import PcPage from "components/page/pc-page";
import ComponentMapper from "components/wrapper";
import { PageProps } from "constants/types";
import {
  getGlobalPageProps,
  getPageProps,
  getPathAndLanguageFromShortLink,
} from "helpers/data";
import {
  generateUrl,
  getPathFromSlug,
  getPathSegments,
  isShortLink,
} from "helpers/data/utils";
import { getCountryAndLanguage } from "helpers/locale/utils";
import { getFirstParam } from "helpers/utils";
import { has, isEmpty } from "lodash-es";
import {
  GetStaticPaths,
  GetStaticPathsResult,
  GetStaticProps,
  NextPage,
} from "next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Custom404 from "pages/404";
import Custom500 from "pages/500";
import localeData from "../../locale-data.json";

const DetailWrapper = dynamic(() => import("components/page"));

const Page: NextPage<PageProps> = ({
  content,
  isPage,
  entity,
  locale,
  ...additional
}) => {
  const router = useRouter();

  if (isEmpty(content)) return <Custom404 />;

  if (content?.error === 500 && has(content, "errors")) {
    if (typeof window !== "undefined" && content.errors)
      void router.push(
        `/500?errors=${encodeURIComponent(JSON.stringify(content.errors))}`,
      );
    return <Custom500 errors={content.errors ?? null} />;
  }

  if (isPage && isEmpty(content.content) && isEmpty(content.pc)) {
    return <Custom500 errors={[{ message: "No Content" }]} />;
  }

  if (!isPage) {
    return (
      <DetailWrapper
        entity={entity}
        content={content}
        {...(content?.downloads && { downloads: content.downloads })}
        {...(content?.videoDownloads && {
          videoDownloads: content.videoDownloads,
        })}
        additional={additional}
      />
    );
  }

  if (isEmpty(content.content) && isEmpty(content.interactiveMarkers)) {
    return <PcPage content={content} />;
  }

  return (
    <>
      {!isEmpty(content.page) && (
        <Overview
          content={content.page}
          pc={content.pc}
          header={additional.header}
          interactiveMarkers={content.interactiveMarkers}
        />
      )}
      <ComponentMapper
        components={content.content}
        page={content.page}
        pc={content.pc}
        base={additional?.header?.base}
        nav={additional?.nav}
        {...(content?.downloads &&
          Boolean(content?.pc?.[0]?.product_tag?.length) && {
            downloads: content.downloads,
          })}
      />
    </>
  );
};

export const getStaticProps: GetStaticProps<PageProps> = async ({
  params: { locale: localeParam, slug },
  preview,
}) => {
  const locale = getFirstParam(localeParam);

  const locales = localeData.locales;

  if (!locales.includes(locale)) {
    const props = await getGlobalPageProps("k_en");
    return {
      props,
      notFound: true,
      revalidate: +(process.env.PAGE_ERROR_CACHE_VALIDITY ?? 30), // 1 minute, 30 seconds if error page
    };
  }

  const [country] = getCountryAndLanguage(locale);
  const path = `/${getPathFromSlug(slug)}`;

  // Handle short links
  if (typeof slug === "string" || slug?.length === 1) {
    const shortLinkCandidate = Array.isArray(slug) ? slug[0] : slug;
    if (isShortLink(shortLinkCandidate)) {
      const pathAndLanguage = await getPathAndLanguageFromShortLink(
        country,
        shortLinkCandidate,
      );

      let redirectPath = pathAndLanguage.path;
      redirectPath = redirectPath?.startsWith("/")
        ? redirectPath?.slice(1)
        : redirectPath;

      return {
        redirect: {
          destination: redirectPath
            ? generateUrl(
                `${country}_${pathAndLanguage.language}`,
                redirectPath,
              )
            : "/",
          permanent: true,
        },
      };
    }
  }

  const { props, notFound } = await getPageProps(
    locale,
    locales,
    path,
    preview,
  );

  return {
    props,
    notFound: !!notFound,
    revalidate:
      !notFound && !props.content.error
        ? +(process.env.PAGE_CACHE_VALIDITY ?? 60)
        : +(process.env.PAGE_ERROR_CACHE_VALIDITY ?? 30), // 1 minute, 30 seconds if error page
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const env = (process.env?.APP_ENV ?? "").toUpperCase();

  // so on dev it should allow all urls and make a API request based on the path and check if an entity exists, otherwise its a page
  // on build, it should map out all urls, and dont allow funny urls
  if (process.env.dev_mode) {
    return {
      paths: [],
      fallback: true,
    };
  }

  const { hget, hgetall } = getRedis();

  // entities per lang, and merge
  const locales = localeData.buildLocales;

  let paths = [];
  for await (const locale of locales) {
    const root_page_id: string = await hget(
      `${locale}:globals`,
      "root_page_id",
    );
    const entities_path: { [entity: string]: string } = await hgetall(
      `${locale}:entities-path`,
    );

    const routes = Object.entries(entities_path).reduce<
      GetStaticPathsResult["paths"]
    >((routes, [entity, path]) => {
      // skip to not get an error for duplicate "/" paths
      if (entity === root_page_id || path === "/") {
        return routes;
      }

      const segments = getPathSegments(path);

      // Skip if the current path is deeper than the build depth
      if (
        segments.length >
        Number(
          process.env?.[`BUILD_DEPTH_${env}`] ?? process.env?.BUILD_DEPTH ?? 2,
        )
      ) {
        return routes;
      }

      return [
        ...routes,
        {
          params: {
            slug: segments,
            locale,
          },
        },
      ];
    }, []);

    paths = [...paths, ...routes];
  }

  return {
    paths,
    fallback: "blocking",
  };
};

export default Page;
